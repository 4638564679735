<template>
  <div>
    <el-form ref="form" label-width="300px">
      <MyFormItem label="Table Index" tooltip="Table Index đang active">
        <el-input class="form-input" v-model="data.TableIndex" size="normal" disabled/>
      </MyFormItem>
      <el-button type="primary" @click="refreshData()">
        Refresh
      </el-button>
      <el-table :data="data.GroupConfig" border>
        <el-table-column
            prop="GroupName"
            label="Name"/>
        <el-table-column
            prop="PuIndex"
            label="Pay User index">
        </el-table-column>
        <el-table-column
            prop="PuCount"
            label="Pay User count">
        </el-table-column>
        <el-table-column
            prop="Fe1Index"
            label="Free User 1 index">
        </el-table-column>
        <el-table-column
            prop="Fe1Count"
            label="Free User 1 count">
        </el-table-column>
        <el-table-column
            prop="Fe2Index"
            label="Free User 2 index">
        </el-table-column>
        <el-table-column
            prop="Fe2Count"
            label="Free User 2 count">
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>

<script>

import MyFormItem from "@/views/loop-machine/component/myFormItem.vue"
import request from "@/utils/request-service-base";
import {Message} from "element-ui";
import moment from 'moment'

export default {
  components:{
    MyFormItem
  },
  props: {
    data: {},
  },
  methods: {
    async refreshData() {
      try {
        this.$root.$emit("event-change-loading", true);
        let response = await request({
          url: `/maintain/leaderboard/personal/get-leaderboard?typeLb=${this.data.TypeLb}`,
          method: `get`,
        });

        this.$root.$emit("event-change-loading", false);

        this.data = response.data;
      }catch (ex) {
        Message({
          message: `Get leaderboard error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>