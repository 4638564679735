<template>
  <div>
    <el-header height="300">
      <el-button type="primary" v-if="data.IsActive === true" @click="changeActive(false)">
        Active - Click to Disable
      </el-button>
      <el-button type="danger" v-if="data.IsActive === false" @click="changeActive(true)">
        Disable - Click to Active
      </el-button>
    </el-header>

    <el-form ref="form" label-width="300px">
      <MyFormItem label="Status Leaderboard" tooltip="Current Status">
        <el-input class="form-input" v-model="data.StatusLb" size="normal" disabled/>
      </MyFormItem>

      <MyFormItem label="Last Modifier" tooltip="Last Modifier">
        <el-input class="form-input" v-model="data.LastModifier" size="normal" disabled/>
      </MyFormItem>

      <MyFormItem label="Last Modify Date" tooltip="Last Modify Date">
        <el-date-picker
            v-model="lastModify"
            type="datetime"
            value-format="timestamp"
            disabled
        >
        </el-date-picker>
      </MyFormItem>

      <MyFormItem label="EventId" tooltip="">
        <el-input class="form-input" v-model="data.EventId" size="normal" readonly/>
      </MyFormItem>

      <MyFormItem label="Event Name" tooltip="Tên của event">
        <el-input class="form-input" v-model="data.EventName" placeholder="Example: EventName LBQ 123" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="Coming Soon" tooltip="Coming Soon Time">
        <el-date-picker
            v-model="comingSoonTime"
            type="datetime"
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>
      <MyFormItem label="Start Time" tooltip="Start Time">
        <el-date-picker
            v-model="startTime"
            type="datetime"
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>
      <MyFormItem label="End Time" tooltip="End Time">
        <el-date-picker
            v-model="endTime"
            type="datetime"
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>

      <MyFormItem label="Affect From Version" tooltip="event xuất hiện từ phiên bản nào">
        <el-input class="form-input" v-model="data.AffectFromVersion" placeholder="Example: 1.65.0" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="Affect To Version" tooltip="event xuất hiện đến phiên bản nào">
        <el-input class="form-input" v-model="data.AffectToVersion" placeholder="Example: 1.65.0" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="Current Index Config" tooltip="Index config sử dụng cho đợt event sau">
        <el-input-number v-model="data.ConfigIndex" size="normal" label="label" :step="1" :controls="true" :min="0"/>
      </MyFormItem>

      <!-- Reward List -->
      <UploadTitle>Reward List</UploadTitle>
      <MyFormItem label="Reward File" tooltip="Đẩy file Reward lên">
        <el-row :gutter="20" type="flex" justify="start">
          <el-col :offset="0">
            <el-upload
                drag
                action=""
                :file-list="rewardFileList"
                :auto-upload="false"
                ref=""
                :multiple="true"
                :on-change="handleChangeRewardFileList"
                :on-remove="handleChangeRewardFileList"
                class="file-list-item">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
            </el-upload>
          </el-col>
          <el-col  :offset="0">
            <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
              <el-button size="default" @click="handleDownloadCurrentRewardFile"><i class="el-icon-download"></i> Download current config</el-button>
            </el-tooltip>
          </el-col>
        </el-row>
      </MyFormItem>

      <el-collapse class="space-to-bottom" :accordion="false" v-if="data.RewardList.length > 0">
        <el-collapse-item title="View Imported Data">
          <el-table :data="data.RewardList" border>
            <el-table-column
                prop="Name"
                label="Name"
                width="150"
            />
            <el-table-column
                label="Reward List"
            >
              <template #default="propsGift">
                <el-table :data="propsGift.row.Gift" border>
                  <el-table-column
                      prop="id"
                      label="Index"
                      width="100"/>
                  <el-table-column
                      prop="loopSet"
                      label="LoopSet"
                      :filters="loopSetFilter"
                      :filter-method="filterHandler"
                  />
                  <el-table-column
                      prop="title"
                      label="Title"/>
                  <el-table-column
                      prop="rankMin"
                      label="RankMin"/>
                  <el-table-column
                      prop="rankMax"
                      label="RankMax"/>
                  <el-table-column
                      prop="requireScore"
                      label="RequireScore"/>
                  <el-table-column
                      prop="rewards"
                      label="Rewards"
                      width="500">
                    <template #default="propsRewards">
                      <el-table :data="propsRewards.row.rewards" border>
                        <el-table-column
                            prop="code"
                            label="Code">
                        </el-table-column>
                        <el-table-column
                            prop="value"
                            label="Value"
                            width="100">
                        </el-table-column>
                      </el-table>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>


          </el-table>
        </el-collapse-item>
      </el-collapse>

      <!-- Group Config -->
      <UploadTitle>GroupConfig List</UploadTitle>
      <MyFormItem label="GroupConfig File" tooltip="Đẩy file GroupConfig lên">
        <el-row :gutter="20" type="flex" justify="start">
          <el-col :offset="0">
            <el-upload
                drag
                action=""
                :file-list="groupConfigFileList"
                :auto-upload="false"
                ref=""
                :on-change="handleChangeGroupConfigFileList"
                :on-remove="handleChangeGroupConfigFileList"
                class="file-list-item">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
            </el-upload>
          </el-col>
          <el-col  :offset="0">
            <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
              <el-button size="default" @click="handleDownloadCurrentGroupConfigFile"><i class="el-icon-download"></i> Download current config</el-button>
            </el-tooltip>
          </el-col>
        </el-row>
      </MyFormItem>

      <el-collapse class="space-to-bottom" :accordion="false" v-if="data.GroupConfig.length > 0">
        <el-collapse-item title="View Imported Data">
          <el-table :data="data.GroupConfig" border>
            <el-table-column
                prop="TypeUser"
                label="Type User (Cheater, Tester, Normal)"/>
            <el-table-column
                prop="Country"
                label="Country (All, Tier1, Tier3, Other)"/>
            <el-table-column
                prop="Region.Min"
                label="Region Min"/>
            <el-table-column
                prop="Region.Max"
                label="Region Max"/>
            <el-table-column
                prop="TotalLeaderBoard"
                label="Total LeaderBoard"/>
            <el-table-column
                prop="MaxUserInLeaderBoard"
                label="Max User In LeaderBoard"/>
            <el-table-column
                prop="MaxPayUser"
                label="Max PayUser"/>
            <el-table-column
                prop="MaxFreeUser1"
                label="Max FreeUser1"/>
            <el-table-column
                prop="RewardName"
                label="Reward Name"/>
            <el-table-column
              label="BossInfo"
              width="400"
            >
              <template #default="propsBossInfo">
                <el-table :data="propsBossInfo.row.BossInfo" border>
                  <el-table-column
                    prop="BossId"
                    label="BossId"
                  />
                  <el-table-column
                      prop="MaxHp"
                      label="MaxHp"
                  />
                  <el-table-column
                      prop="RewardName"
                      label="Reward Name"
                  />
                </el-table>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>

      <!-- Boss Settings -->
      <h2>Boss Settings</h2>
      <el-form label-width="300px">
        <MyFormItem label="Count" tooltip="Số lượng boss trong 1 event">
          <el-input-number v-model="data.BossConfig.Count" size="normal" label="Boss Count" :min="1" :max="3"
                           :step="1" :controls="true" controls-position="both" disabled>
          </el-input-number>
        </MyFormItem>
        <MyFormItem label="Active Time" tooltip="Khoảng thời gian giữa 2 con boss - session 1 con boss (giây)">
          <TimeInput :value="data.BossConfig.ActiveTime" :on-change="handleOnBossActiveTimeChange"/>
        </MyFormItem>

        <MyFormItem label="Living Time" tooltip="Thời gian con boss hoạt động trong session trên (giây)">
          <TimeInput :value="data.BossConfig.LivingTime" :on-change="handleOnBossLivingTimeChange"/>
        </MyFormItem>

        <MyFormItem label="Waiting Time" tooltip="Thời gian chờ khi con boss session trước hết hoạt động đến khi bắt đầu session tiếp theo (giây)">
          <el-input class="form-input" v-model="bossWaitingTime" size="normal" disabled/>
          <SpanTime :numberOfSeconds="bossWaitingTime"/>
        </MyFormItem>
      </el-form>

      <!-- Reward Progress -->
      <!--      <el-row v-if='data.StatusLb === "Rewarding"'>-->
      <!--        <MyFormItem label="GroupRewarded" tooltip="">-->
      <!--          <el-input class="form-input" v-model="data.RewardProcess.GroupRewarded" size="normal" disabled/>-->
      <!--        </MyFormItem>-->
      <!--        <MyFormItem label="Group Current Reward" tooltip="">-->
      <!--          <el-input class="form-input" v-model="data.RewardProcess.GroupCurrentReward" size="normal" disabled/>-->
      <!--        </MyFormItem>-->
      <!--        <MyFormItem label="Current Lb Index" tooltip="">-->
      <!--          <el-input class="form-input" v-model="data.RewardProcess.GroupRewardedLbIndex" size="normal" disabled/>-->
      <!--        </MyFormItem>-->
      <!--        <MyFormItem label="Current User Index" tooltip="">-->
      <!--          <el-input class="form-input" v-model="data.RewardProcess.UserIndex" size="normal" disabled/>-->
      <!--        </MyFormItem>-->
      <!--      </el-row>-->

      <br/>

      <el-footer>
        <el-button type="primary" @click="applyData()">
          Apply Data
        </el-button>
      </el-footer>

    </el-form>
  </div>
</template>

<script>

import moment from "moment";
import MyFormItem from "@/views/loop-machine/component/myFormItem.vue"
import UploadTitle from "@/views/loop-machine/component/uploadTitle.vue"

import DownloadFile from '@/helpers/downloadFile'
import GenerateMLBFileContent from '@/helpers/generateMLBFileContent'
import ParseMLBfile from '@/helpers/parseMLBfile'
import ReadFile from '@/helpers/readFile'
import {Message} from "element-ui";
import request from "@/utils/request-service-base";
import store from "@/store";
import TimeInput from "@/views/loop-machine/component/timeInput.vue";
import SpanTime from "@/views/loop-machine/component/spanTime.vue";

function noop(typeLb) {}

export default {
  components:{
    SpanTime,
    TimeInput,
    MyFormItem,
    UploadTitle
  },
  props: {
    data: {},
    onChange: {
      type: Function,
      default: noop
    },
  },
  computed: {
    comingSoonTime: {
      get: function() {
        return this.data.Time.ComingSoon * 1000;
      },
      set: function (value) {
        this.data.Time.ComingSoon = value / 1000;
      }
    },
    startTime: {
      get: function() {
        return this.data.Time.Start * 1000;
      },
      set: function (value) {
        this.data.Time.Start = value / 1000;
      }
    },
    endTime: {
      get: function() {
        return this.data.Time.End * 1000;
      },
      set: function (value) {
        this.data.Time.End = value / 1000;
      }
    },
    lastModify: {
      get: function() {
        return this.data.LastModifyDate * 1000;
      },
      set: function (value) {
        this.data.LastModifyDate = value / 1000;
      }
    },

    rewardFileList: {
      get: function() {
        let ret = [];

        for (let i = 0; i < this.data.RewardList.length; i++) {
          ret.push(
              {
                name: `${this.data.RewardList[i].Name}`,
                rawText: this.generateMLBFileContentOfReward(this.data.RewardList[i].Gift),
              });
        }

        return ret;
      }
    },
    groupConfigFileList: {
      get: function() {
        let ret = [];

        if (this.data.GroupConfig.length > 0) {
          ret.push(
              {
                name: `GroupConfig.mlb`,
                rawText: this.generateMLBFileContentOfGroupConfig(this.data.GroupConfig),
              });
        }

        return ret;
      }
    },
    bossWaitingTime() {
      return this.data.BossConfig.ActiveTime - this.data.BossConfig.LivingTime;
    },

    loopSetFilter() {
      let retData = [];
      for (let i = 0; i < 10; i++) {
        retData.push({
          text: `${i}`, value: i
        });
      }

      return retData;
    },
  },
  methods: {
    async changeActive(isActive) {
      try {
        this.$root.$emit("event-change-loading", true);
        await request({
          url: `/maintain/leaderboard/personal/change-leaderboard-active`,
          method: `post`,
          data: {
            TypeLb: this.data.TypeLb,
            IsActive: isActive,
            Modifier: store.getters.email
          }
        });

        Message({
          message: "Apply data success",
          type: "success",
          duration: 5 * 1000,
        });

        this.$root.$emit("event-change-loading", false);

        this.data.IsActive = isActive;
        this.onChange(this.data.TypeLb);
      }catch (ex) {
        Message({
          message: `Change Active error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async applyData() {
      try {
        this.$root.$emit("event-change-loading", true);
        let dataRequest = this.data;
        dataRequest.Modifier = store.getters.email;
        let response = await request({
          url: `/maintain/leaderboard/personal/update-leaderboard`,
          method: `post`,
          data: dataRequest
        });

        Message({
          message: "Apply data success",
          type: "success",
          duration: 5 * 1000,
        });

        this.$root.$emit("event-change-loading", false);
        this.$root.$emit("event-refresh-data", false);

      }catch (ex) {
        Message({
          message: `Apply data error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },

    //reward list
    generateMLBFileContentOfReward(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let data = listData[j];
        let tempString = `${data.id}\t${data.loopSet}\t${data.title}\t${data.rankMin}\t${data.rankMax}\t${data.requireScore}\t${JSON.stringify(data.rewards)}`;
        dataList.push(tempString);
      }

      return GenerateMLBFileContent(`LeaderBoardGiftsConfig`,
          ['Index', 'LoopSet', 'Title', 'RankMin', 'RankMax', 'RequiredScore', 'Rewards'],
          ['int', 'int', 'string', 'int', 'int', 'int', 'string'],
          dataList);
    },

    async handleChangeRewardFileList(file, fileList) {
      this.data.RewardList = [];

      this.$root.$emit("event-change-loading", true);

      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].raw) {
          fileList[i].rawText = await ReadFile(fileList[i].raw);
        }

        let rewardTemp = ParseMLBfile(fileList[i].rawText);
        let rewardsData = {
          Name: fileList[i].name.split('.mlb')[0],
          Gift: [],
        };

        for (let j = 0; j < rewardTemp.length; j++) {
          let data = rewardTemp[j];
          rewardsData.Gift.push({
            id: parseInt(data.Index),
            loopSet: parseInt(data.LoopSet),
            title: data.Title,
            rankMin: parseInt(data.RankMin),
            rankMax: parseInt(data.RankMax),
            requireScore: parseInt(data.RequiredScore),
            rewards: JSON.parse(data.Rewards),
          })
        }

        this.data.RewardList.push(rewardsData);
      }

      this.$root.$emit("event-change-loading", false);
    },

    handleDownloadCurrentRewardFile() {
      for (let i = 0; i < this.data.RewardList.length; i++) {
        DownloadFile(`${this.data.RewardList[i].Name}.mlb`,
            this.generateMLBFileContentOfReward(this.data.RewardList[i].Gift));
      }
    },

    //groupConfig list
    generateMLBFileContentOfGroupConfig(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let data = listData[j];
        let tempString = `${data.TypeUser}\t${data.Country}\t${data.Region.Min}\t${data.Region.Max}\t${data.TotalLeaderBoard}\t${data.MaxUserInLeaderBoard}\t${data.MaxPayUser}\t${data.MaxFreeUser1}\t${data.RewardName}\t${JSON.stringify(data.BossInfo)}`;
        dataList.push(tempString);
      }

      return GenerateMLBFileContent(`GroupConfig`,
          ['TypeUser', 'Country', 'Region_Min', 'Region_Max', 'TotalLeaderBoard', 'MaxUserInLeaderBoard', 'MaxPayUser', 'MaxFreeUser1', "RewardName", "BossInfo"],
          ['string', 'string', 'int', 'int', 'int', 'int', 'int', 'int', 'string', 'string'],
          dataList);
    },

    async handleChangeGroupConfigFileList(file, fileList) {
      this.data.GroupConfig = [];

      if (fileList.length !== 1) {
        return;
      }

      this.$root.$emit("event-change-loading", true);

      if (fileList[0].raw) {
        fileList[0].rawText = await ReadFile(fileList[0].raw);
      }

      let rewardTemp = ParseMLBfile(fileList[0].rawText);
      for (let j = 0; j < rewardTemp.length; j++) {
        let data = rewardTemp[j];
        this.data.GroupConfig.push({
          TypeUser: data.TypeUser,
          Country: data.Country,
          Region: {
            Min: data.Region_Min,
            Max: data.Region_Max,
          },
          TotalLeaderBoard: data.TotalLeaderBoard,
          MaxUserInLeaderBoard: data.MaxUserInLeaderBoard,
          MaxPayUser: data.MaxPayUser,
          MaxFreeUser1: data.MaxFreeUser1,
          RewardName: data.RewardName,
          BossInfo: JSON.parse(data.BossInfo),
        });
      }

      this.$root.$emit("event-change-loading", false);
    },

    handleDownloadCurrentGroupConfigFile() {
      if (this.data.GroupConfig.length <= 0) {
        return;
      }

      DownloadFile('GroupConfig.mlb',
          this.generateMLBFileContentOfGroupConfig(this.data.GroupConfig));
    },

    handleOnBossActiveTimeChange(value){
      this.data.BossConfig.ActiveTime = value;
    },
    handleOnBossLivingTimeChange(value){
      this.data.BossConfig.LivingTime = value;
    },

    filterHandler(value, row, column) {
      let property = column['property']
      return row[property] === value
    }
  }
};
</script>