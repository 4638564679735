<template>
  <div>
    <el-header height="300">
      <div v-if="data.TypeLb !== `RbLittle`">
        <el-button type="primary" v-if="data.IsActive === true" @click="changeActive(false)">
          Active - Click to Disable
        </el-button>
        <el-button type="danger" v-if="data.IsActive === false" @click="changeActive(true)">
          Disable - Click to Active
        </el-button>
      </div>
    </el-header>

    <el-form ref="form" label-width="300px">
      <MyFormItem label="Last Modifier" tooltip="Last Modifier">
        <el-input class="form-input" v-model="data.LastModifier" size="normal" disabled/>
      </MyFormItem>

      <MyFormItem label="EventId" tooltip="">
        <el-input class="form-input" v-model="data.EventId" size="normal" readonly/>
      </MyFormItem>

      <MyFormItem label="Last Modify Date" tooltip="Last Modify Date">
        <el-date-picker
            v-model="lastModify"
            type="datetime"
            value-format="timestamp"
            disabled
        >
        </el-date-picker>
      </MyFormItem>

      <MyFormItem label="Coming Soon" tooltip="Coming Soon Time">
        <el-date-picker
            v-model="comingSoonTime"
            type="datetime"
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>
      <MyFormItem label="Start Time" tooltip="Start Time">
        <el-date-picker
            v-model="startTime"
            type="datetime"
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>
      <MyFormItem label="End Time" tooltip="End Time">
        <el-date-picker
            v-model="endTime"
            type="datetime"
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>

      <MyFormItem label="Affect From Version" tooltip="event xuất hiện từ phiên bản nào">
        <el-input class="form-input" v-model="data.AffectFromVersion" placeholder="Example: 1.65.0" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="Affect To Version" tooltip="event xuất hiện đến phiên bản nào">
        <el-input class="form-input" v-model="data.AffectToVersion" placeholder="Example: 1.65.0" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="Current Index Config" tooltip="Index config sử dụng cho đợt event sau">
        <el-input-number v-model="data.ConfigIndex" size="normal" label="label" :step="1" :controls="true" :min="0"/>
      </MyFormItem>

      <br/>

      <el-footer>
        <el-button type="primary" @click="applyData()">
          Apply Data
        </el-button>
      </el-footer>

    </el-form>
  </div>
</template>

<script>

import MyFormItem from "@/views/loop-machine/component/myFormItem.vue"

import {Message} from "element-ui";
import request from "@/utils/request-service-base";
import store from "@/store";

function noop(typeLb) {}

export default {
  components:{
    MyFormItem,
  },
  props: {
    data: {},
    onChange: {
      type: Function,
      default: noop
    },
  },
  data: () => {
    return {
      updateMsg: {
        msg: "",
      },
    }
  },
  computed: {
    comingSoonTime: {
      get: function() {
        return this.data.ComingSoon * 1000;
      },
      set: function (value) {
        this.data.ComingSoon = value / 1000;
      }
    },
    startTime: {
      get: function() {
        return this.data.Start * 1000;
      },
      set: function (value) {
        this.data.Start = value / 1000;
      }
    },
    endTime: {
      get: function() {
        return this.data.End * 1000;
      },
      set: function (value) {
        this.data.End = value / 1000;
      }
    },
    lastModify: {
      get: function() {
        return this.data.LastModifyDate * 1000;
      },
      set: function (value) {
        this.data.LastModifyDate = value / 1000;
      }
    },
  },
  methods: {
    async changeActive(isActive) {
      try {
        this.$root.$emit("event-change-loading", true);
        await request({
          url: `/maintain/event/change-event-active`,
          method: `post`,
          data: {
            TypeEvent: this.data.TypeEvent,
            IsActive: isActive,
            Modifier: store.getters.email
          }
        });

        Message({
          message: "Apply data success",
          type: "success",
          duration: 5 * 1000,
        });

        this.$root.$emit("event-change-loading", false);

        this.data.IsActive = isActive;
        this.onChange(this.data.TypeEvent);
      }catch (ex) {
        Message({
          message: `Change Active error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async applyData() {
      try {
        this.$root.$emit("event-change-loading", true);
        let dataRequest = this.data;
        dataRequest.Modifier = store.getters.email;
        let response = await request({
          url: `/maintain/event/update-event`,
          method: `post`,
          data: dataRequest
        });

        Message({
          message: "Apply data success",
          type: "success",
          duration: 5 * 1000,
        });

        this.$root.$emit("event-change-loading", false);
        this.$root.$emit("event-refresh-data", false);

      }catch (ex) {
        Message({
          message: `Apply data error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
  }
};
</script>