<template>
  <el-form ref="form" label-width="300px">
    <span>Get User By User Status</span>

    <MyFormItem label="User Status" tooltip="User status">
      <el-select v-model="listUserByStatus.userStatus">
        <el-option v-for="data in this.userStatusFilterSelect" :key="data.value" :label="data.value" :value="data.value"/>
      </el-select>
    </MyFormItem>

    <el-button type="primary" @click="onTapGetListUser()">
      Get List User
    </el-button>

    <el-pagination
        background layout="prev, pager, next"
        :total="listUserByStatus.total"
        :page-size="listUserByStatus.limit"
        :current-page="listUserByStatus.page"
        @current-change="onListUserPageChange"
        v-if="listUserByStatus.listData.length > 0"
    />
    <el-table :data="listUserByStatus.listData" border v-if="listUserByStatus.listData.length > 0">
      <el-table-column
          prop="Index"
          label="Index"
          width="100"/>
      <el-table-column
          prop="UserId"
          label="UserId"/>
      <el-table-column
          prop="Country"
          label="Country"/>
      <el-table-column
          prop="UserStatus"
          label="UserStatus"/>
      <el-table-column
          label="Command"
          width="550"
      >
        <template #default="scope">
          <el-button type="primary" round @click="handleTapDetail(scope.row)">
            Get Detail
          </el-button>

          <el-button type="primary" round v-if="scope.row.UserStatus !== `Normal`" @click="handleTapChangeUserStatus(scope.row, `Normal`)">
            Change to Normal
          </el-button>

          <el-button type="primary" round v-if="scope.row.UserStatus !== `Cheater`"  @click="handleTapChangeUserStatus(scope.row, `Cheater`)">
            Change to Cheater
          </el-button>

          <el-button type="primary" round v-if="scope.row.UserStatus !== `Tester`" @click="handleTapChangeUserStatus(scope.row, `Tester`)">
            Change to Tester
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <br/>
    <br/>

    <div ref="user-info">
      <span>Get User info</span>

      <MyFormItem label="UserId" tooltip="" >
        <el-input class="form-input" v-model="userInfoData.userId" size="normal"/>
      </MyFormItem>

      <el-button type="primary" @click="getUserInfo()">
        Get user info
      </el-button>

      <el-table :data="userInfoData.result" border v-if="userInfoData.result.length > 0">
        <el-table-column
            prop="UserStatus"
            label="UserStatus"/>
        <el-table-column
            prop="Country"
            label="Country"/>
        <el-table-column
            prop="GunPower"
            label="GunPower"/>
        <el-table-column
            prop="IsPayUser"
            label="IsPayUser"/>
        <el-table-column
            prop="Region"
            label="Region"/>
        <el-table-column
            prop="Gold"
            label="Gold"/>
        <el-table-column
            prop="Version"
            label="Version"/>
        <el-table-column
            prop="LbqGroupName"
            label="LbqGroupName"/>
        <el-table-column
            prop="EndlessGroupName"
            label="EndlessGroupName"/>
        <el-table-column
            prop="RaidbossGrandGroupName"
            label="RaidbossGrandGroupName"/>
        <el-table-column
            prop="RaidbossLittleGroupName"
            label="RaidbossLittleGroupName"/>
        <el-table-column
            prop="VersusGroupName"
            label="VersusGroupName"/>
      </el-table>

      <br/>
      <br/>

      <el-table :data="userInfoData.logData" border v-if="userInfoData.logData.length > 0">
        <el-table-column
            prop="TypeCheat"
            label="TypeCheat"
            width="300"/>
        <el-table-column
            label="Detail"
        >
          <template #default="Detail">
            <el-table :data="Detail.row.Detail" border>
              <el-table-column
                  prop="Description"
                  label="Description"
              />
              <el-table-column
                  prop="Time"
                  label="Time"/>
            </el-table>
          </template>
        </el-table-column>
      </el-table>



      <br/>
      <br/>
    </div>

    <div ref="add-user">
      <span>Add User</span>

      <MyFormItem label="UserId" tooltip="">
        <el-input class="form-input" v-model="addUserData.userId" size="normal"/>
      </MyFormItem>

      <MyFormItem label="IsPayUser" tooltip="">
        <el-checkbox v-model="addUserData.isPayUser" size="large" />
      </MyFormItem>

      <MyFormItem label="Region" tooltip="">
        <el-input-number v-model="addUserData.region" :step="1" size="small" :min="1"/>
      </MyFormItem>

      <MyFormItem label="Country" tooltip="">
        <el-input class="form-input" v-model="addUserData.country" size="normal"/>
      </MyFormItem>

      <MyFormItem label="User Status" tooltip="User status">
        <el-select v-model="addUserData.userStatus">
          <el-option v-for="data in this.userStatusSelect" :key="data.value" :label="data.value" :value="data.value"/>
        </el-select>
      </MyFormItem>

      <el-table :data="addUserData.result" border v-if="addUserData.result.length > 0">
        <el-table-column
            prop="Message"
            label="Message"/>
        <el-table-column
            prop="UserId"
            label="UserId"/>
      </el-table>

      <el-button type="primary" @click="addUser()">
        Add User
      </el-button>

      <br/>
      <br/>
    </div>

    <div ref="change-user-status">
      <span>Change user status</span>

      <MyFormItem label="UserId" tooltip="">
        <el-input class="form-input" v-model="changeUserStatusData.userId" size="normal"/>
      </MyFormItem>

      <MyFormItem label="User Status" tooltip="User status">
        <el-select v-model="changeUserStatusData.userStatus">
          <el-option v-for="data in this.userStatusSelect" :key="data.value" :label="data.value" :value="data.value"/>
        </el-select>
      </MyFormItem>

      <el-table :data="changeUserStatusData.result" border v-if="changeUserStatusData.result.length > 0">
        <el-table-column
            prop="UserStatus"
            label="UserStatus"/>
        <el-table-column
            prop="LbqGroupName"
            label="LbqGroupName"/>
        <el-table-column
            prop="EndlessGroupName"
            label="EndlessGroupName"/>
        <el-table-column
            prop="RaidbossGrandGroupName"
            label="RaidbossGrandGroupName"/>
        <el-table-column
            prop="RaidbossLittleGroupName"
            label="RaidbossLittleGroupName"/>
        <el-table-column
            prop="VersusGroupName"
            label="VersusGroupName"/>
      </el-table>

      <el-button type="primary" @click="changeUserStatus()">
        Change user status
      </el-button>
    </div>
  </el-form>
</template>

<script>
import MyFormItem from "@/views/loop-machine/component/myFormItem.vue";
import request from "@/utils/request-service-base";
import {Message} from "element-ui";
import moment from "moment/moment";

export default {
  components: {
    MyFormItem,
  },
  data: () => {
    return {
      listUserByStatus: {
        userStatus: "Normal",
        pageInput: 1,
        listData: [],
        total: 0,
        page: 0,
        limit: 0,
      },
      userInfoData: {
        userId: "",
        logData: [],
        result: [],
      },
      addUserData: {
        userId: "xyz",
        isPayUser: true,
        region: 1,
        country: "US",
        userStatus: "Normal",
        result: [],
      },
      changeUserStatusData: {
        userId: "",
        userStatus: "Normal",
        result: [],
      },
    }
  },
  computed: {
    userStatusFilterSelect: {
      get: function() {
        return [{
          value: "Tester",
        },{
          value: "Cheater",
        },{
          value: "Normal",
        },{
          value: "CheatWarning",
        }];
      }
    },
    userStatusSelect: {
      get: function() {
        return [{
          value: "Tester",
        },{
          value: "Cheater",
        },{
          value: "Normal",
        }];
      }
    }
  },
  methods: {
    scrollMeTo(refName) {
      let element = this.$refs[refName];
      let top = element.offsetTop;

      window.scrollTo(0, top);
    },
    async changeUserStatus(){
      try {
        this.changeUserStatusData.result = [];
        this.$root.$emit("event-change-loading", true);
        let response = await request({
          url: `/maintain/leaderboard/personal/change-user-type`,
          method: `post`,
          data: {
            UserId: this.changeUserStatusData.userId,
            UserType: this.changeUserStatusData.userStatus,
          }
        });

        this.$root.$emit("event-change-loading", false);

        let dataResult = {};
        dataResult.UserStatus = response.data.UserStatus;

        // eslint-disable-next-line no-prototype-builtins
        if (response.data.hasOwnProperty("Lbq")) {
          dataResult.LbqGroupName = response.data.Lbq.GroupName;
        }

        // eslint-disable-next-line no-prototype-builtins
        if (response.data.hasOwnProperty("Endless")) {
          dataResult.EndlessGroupName = response.data.Endless.GroupName;
        }

        // eslint-disable-next-line no-prototype-builtins
        if (response.data.hasOwnProperty("RbGrand")) {
          dataResult.RaidbossGrandGroupName = response.data.RbGrand.GroupName;
        }

        // eslint-disable-next-line no-prototype-builtins
        if (response.data.hasOwnProperty("RbLittle")) {
          dataResult.RaidbossLittleGroupName = response.data.RbLittle.GroupName;
        }

        // eslint-disable-next-line no-prototype-builtins
        if (response.data.hasOwnProperty("Versus")) {
          dataResult.VersusGroupName = response.data.Versus.GroupName;
        }

        this.changeUserStatusData.result.push(dataResult);
      }
      catch (ex) {
        Message({
          message: `Change user status error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async getUserInfo(){
      try {
        this.userInfoData.result = [];
        this.userInfoData.logData = [];
        this.$root.$emit("event-change-loading", true);
        let response = await request({
          url: `/maintain/leaderboard/personal/get-user-info/${this.userInfoData.userId}`,
          method: `get`,
        });

        this.$root.$emit("event-change-loading", false);

        let responseData = response.data;
        let userData = {};
        userData.UserId = responseData.UserId;
        userData.UserStatus = responseData.UserStatus;
        userData.Country = responseData.Country;
        userData.GunPower = responseData.GunPower;
        userData.IsPayUser = `${responseData.IsPayUser}`;
        userData.Region = responseData.Region;
        userData.Gold = responseData.Gold;
        userData.Version = responseData.Version;

        // eslint-disable-next-line no-prototype-builtins
        if (responseData.hasOwnProperty("Lbq")) {
          userData.LbqGroupName = responseData.Lbq.GroupName;
        }

        // eslint-disable-next-line no-prototype-builtins
        if (responseData.hasOwnProperty("Endless")) {
          userData.EndlessGroupName = responseData.Endless.GroupName;
        }

        // eslint-disable-next-line no-prototype-builtins
        if (responseData.hasOwnProperty("RbGrand")) {
          userData.RaidbossGrandGroupName = responseData.RbGrand.GroupName;
        }

        // eslint-disable-next-line no-prototype-builtins
        if (responseData.hasOwnProperty("RbLittle")) {
          userData.RaidbossLittleGroupName = responseData.RbLittle.GroupName;
        }

        // eslint-disable-next-line no-prototype-builtins
        if (responseData.hasOwnProperty("Versus")) {
          userData.VersusGroupName = responseData.Versus.GroupName;
        }

        this.userInfoData.result.push(userData);

        for (let typeCheat in responseData.LogCheatData) {
          let detail = [];
          for (let i = 0 ;i < responseData.LogCheatData[typeCheat].Detail.length; i++) {
            detail.push({
              Description: responseData.LogCheatData[typeCheat].Detail[i].Description,
              Time: `${moment.unix(responseData.LogCheatData[typeCheat].Detail[i].Time).local().format("DD-MM-YYYY HH:mm")}`
            });
          }

          this.userInfoData.logData.push({
            TypeCheat: typeCheat,
            Detail: detail,
          });
        }
      }
      catch (ex) {
        Message({
          message: `Get user info error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async addUser(){
      try {
        this.addUserData.result = [];
        this.$root.$emit("event-change-loading", true);
        let response = await request({
          url: `/maintain/leaderboard/personal/add-user`,
          method: `post`,
          data: {
            UserId: this.addUserData.userId,
            IsPayUser: this.addUserData.isPayUser,
            Region: this.addUserData.region,
            Country: this.addUserData.country,
            UserStatus: this.addUserData.userStatus,
          }
        });

        this.$root.$emit("event-change-loading", false);

        let dataResult = {};
        dataResult.Message = "Success";
        dataResult.UserId = "";
        if (response.data.UserId !== undefined) {
          dataResult.UserId = response.data.UserId;
        }

        this.addUserData.result.push(dataResult);
      }
      catch (ex) {
        Message({
          message: `Add user error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }

    },
    async onListUserPageChange(val) {
      this.listUserByStatus.pageInput = val;
      await this.getUserByUserStatus();
    },
    async onTapGetListUser() {
      this.listUserByStatus.pageInput = 1;
      await this.getUserByUserStatus();
    },
    async getUserByUserStatus() {
      try {
        this.listUserByStatus.listData = [];

        this.$root.$emit("event-change-loading", true);
        let response = await request({
          url: `/maintain/leaderboard/personal/list-user-data-by-status?page=${this.listUserByStatus.pageInput}`,
          method: `post`,
          data: {
            UserStatus: this.listUserByStatus.userStatus,
          }
        });

        this.$root.$emit("event-change-loading", false);

        let dataResponse = response.data;

        this.listUserByStatus.listData = dataResponse.data;
        this.listUserByStatus.total = dataResponse.paging.total;
        this.listUserByStatus.limit = dataResponse.paging.limit;
        this.listUserByStatus.page = parseInt(dataResponse.paging.page);

        for(let i = 0; i < this.listUserByStatus.listData.length; i++) {
          this.listUserByStatus.listData[i]["Index"] = i + 1;
        }

        this.listUserByStatus.pageInput = this.listUserByStatus.page;
      }
      catch (ex) {
        Message({
          message: `Change user status error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async handleTapDetail(row) {
      //console.log(`row = ${JSON.stringify(row)}`);
      this.scrollMeTo("user-info");

      this.userInfoData.userId = row.UserId;

      await this.getUserInfo();
    },
    async handleTapChangeUserStatus(row, userStatus) {
      //console.log(`row = ${JSON.stringify(row)}`);
      this.scrollMeTo("change-user-status");

      this.changeUserStatusData.userId = row.UserId;
      this.changeUserStatusData.userStatus = userStatus;

      await this.changeUserStatus();

      if (this.changeUserStatusData.result.length > 0) {
        this.listUserByStatus.listData[parseInt(row.Index) - 1].UserStatus = userStatus;
      }
    },
  }
}
</script>