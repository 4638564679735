<template>
  <div>
    <el-header height="300">
      <el-row :gutter="20" type="flex" align="middle" justify="center">
        <el-col :span="6" :offset="0">
          <el-button type="danger" v-if="currentData.IsActive === true" @click="handleChangeState(false)">
            Activating - Click to hold
          </el-button>
          <el-button type="primary" v-if="currentData.IsActive === false" @click="handleChangeState(true)">
            Holding - Click to Active
          </el-button>
        </el-col>
        <el-col :offset="0">
          <h1>THE NEXT EVENT WILL BE SET UP AUTOMATICALLY <span class="red-text">{{ this.nextSetUpCountDown }}</span></h1>
        </el-col>
        <el-col :span="10" :offset="0">
          <el-row>
            <el-button
                v-if="currentErrorMsg.length === 0"
                type="success"
                size="default"
                @click="handleApplyData"
            >Apply Data
            </el-button>
            <el-button
                v-if="currentErrorMsg.length > 0"
                type="danger"
                size="default"
                @click="doNothingFunction"
            >Apply Data
            </el-button>
          </el-row>
          <el-row>
            <ul>
              <li  v-for="msg in this.currentErrorMsg" :key="msg">
                {{ msg }}
              </li>
            </ul>
          </el-row>

        </el-col>
      </el-row>
    </el-header>

    <el-form ref="form" label-width="300px">
      <MyFormItem label="Time Start" tooltip="Thời gian bắt đầu của Event gần nhất tiếp theo (giây)">
        <el-date-picker
            v-model="dateInputTimeStart"
            type="datetime"
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>

      <MyFormItem label="Time Start Server" tooltip="Thời gian bắt đầu của Event gần nhất tiếp theo (giây)">
        <el-date-picker
            v-model="dateInputTimeStartServer"
            type="datetime"
            disabled
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>

      <MyFormItem label="Event Duration" tooltip="Thời gian kéo dài của event (giây)">
        <TimeInput :value="currentData.EventDuration" :on-change="handleOnEventDurationChange"/>
      </MyFormItem>

      <MyFormItem  label="Time Delay" tooltip="Thời gian chờ đến lúc lên Event tiếp theo (giây)">
        <TimeInput :value="currentData.TimeDelay" :on-change="handleOnTimeDelayChange"/>
      </MyFormItem>

      <MyFormItem label="Coming soon before" tooltip="Thời gian Coming Soon trước Time Start bao lâu (giây)">
        <TimeInput :value="currentData.ComingSoonBefore" :on-change="handleOnComingSoonBeforeChange"/>
      </MyFormItem>

      <MyFormItem label="Setup before" tooltip="Thời gian Setup trước Time Start bao lâu (giây)">
        <TimeInput :value="currentData.SetupBefore" :on-change="handleOnSetupBeforeChange"/>
      </MyFormItem>

      <MyFormItem label="Current Index Config" tooltip="Index config sử dụng cho đợt event sau">
        <el-input-number v-model="currentData.CurrentIndexConfig" size="normal" label="label" :step="1" :controls="true" :min="0" :max="currentData.MaxIndexConfig - 1"/>
      </MyFormItem>

      <MyFormItem label="Max Index Config" tooltip="Số lượng config có trong bundle">
        <el-input-number v-model="currentData.MaxIndexConfig" size="normal" label="label" :step="1" :controls="true" :min="1"/>
      </MyFormItem>

      <MyFormItem label="EventName Format" tooltip="EventName ví dụ: RAID BOSS OPERATION {MM/DD}">
        <el-input class="form-input" v-model="currentData.EventNameFormat" size="normal"></el-input>
      </MyFormItem>

      <div>
        <h2>
          <span>Clan Reward Config</span>
        </h2>

        <MyFormItem label="Clan Reward File" tooltip="Đẩy file Clan Reward lên">
          <el-row :gutter="20" type="flex" justify="start">
            <el-col  :offset="0">
              <el-upload
                  drag
                  action=""
                  :file-list="clanRewardFileList"
                  :auto-upload="false"
                  ref=""
                  :on-change="handleChangeClanRewardFileList"
                  :on-remove="handleRemoveClanRewardFileList"
                  class="file-list-item">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
              </el-upload>
            </el-col>
            <el-col  :offset="0">
              <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                <el-button size="default" @click="handleDownloadCurrentClanRewardFile"><i class="el-icon-download"></i> Download current config</el-button>
              </el-tooltip>
            </el-col>
          </el-row>
        </MyFormItem>
        <el-collapse class="space-to-bottom" :accordion="false" v-if="currentData.ClanRewardData.length > 0">
          <el-collapse-item title="View Imported Data">
            <el-table :data="currentData.ClanRewardData" border>
              <el-table-column
                  prop="id"
                  label="Index"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="title"
                  label="Title">
              </el-table-column>
              <el-table-column
                  prop="rankMin"
                  label="RankMin">
              </el-table-column>
              <el-table-column
                  prop="rankMax"
                  label="RankMax">
              </el-table-column>
              <el-table-column
                  prop="requiredScore"
                  label="RequireScore">
              </el-table-column>
              <el-table-column
                  prop="rewards"
                  label="Rewards">
              </el-table-column>
            </el-table>
          </el-collapse-item>
        </el-collapse>

        <h2>
          <span>Clan Segment Config</span>
        </h2>

        <MyFormItem label="Clan Segment File" tooltip="Đẩy file LeaderBoardClanSegmentConfig.mlb lên">
          <el-row :gutter="20" type="flex" justify="start">
            <el-col  :offset="0">
              <el-upload
                  drag
                  action=""
                  :file-list="clanSegmentFileList"
                  :auto-upload="false"
                  ref=""
                  :on-change="handleChangeClanSegmentFileList"
                  :on-remove="handleRemoveClanSegmentFileList"
                  class="file-list-item">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
              </el-upload>
            </el-col>
            <el-col  :offset="0">
              <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                <el-button size="default" @click="handleDownloadCurrentClanSegmentFile"><i class="el-icon-download"></i> Download current config</el-button>
              </el-tooltip>
            </el-col>
          </el-row>
        </MyFormItem>
        <el-collapse class="space-to-bottom" :accordion="false" v-if="this.currentData.ClanSegmentData.length > 0">
          <el-collapse-item title="View Imported Data">
            <el-table :data="this.currentData.ClanSegmentData" border>
              <el-table-column
                  prop="name"
                  label="GroupName">
              </el-table-column>
              <el-table-column
                  prop="total"
                  label="TotalLDB">
              </el-table-column>
              <el-table-column
                  prop="size"
                  label="TotalClan">
              </el-table-column>
            </el-table>
          </el-collapse-item>
        </el-collapse>
      </div>

    </el-form>
  </div>
</template>

<script>

import moment from "moment/moment";
import MyFormItem from "@/views/loop-machine/component/myFormItem.vue";
import TimeInput from "@/views/loop-machine/component/timeInput.vue";
import GenerateMLBFileContent from "@/helpers/generateMLBFileContent";
import ReadFile from "@/helpers/readFile";
import ParseMLBfile from "@/helpers/parseMLBfile";
import DownloadFile from "@/helpers/downloadFile";
import request from "@/utils/request-service-base";
import {Message} from "element-ui";

function noop() {}

export default {
  name: "loopMachine",
  components: {
    TimeInput,
    MyFormItem,
  },
  data() {
    return {
      //server data
      loopData: {
        TypeLb: "Lbq",
        TimeStart: 0,
        EventDuration: 0,
        TimeDelay: 0,
        SetupBefore: 0,
        ComingSoonBefore: 0,
        CurrentIndexConfig: 0,
        MaxIndexConfig: 2,
        ClanRewardData: [],
        ClanSegmentData: [],
        EventNameFormat: "RAID BOSS EVENT {MM/DD}",
        LoopMachineState: "Holding",
        IsActive: false,
      },

      countDownNextSetUp: 0,

      dateInputTimeStartServer: 0,
    }
  },
  props: {
    data: {},
  },
  methods: {
    doNothingFunction(){
    },

    async handleApplyData(){
      try {
        this.$root.$emit("loop-machine-change-loading", true);

        let response = await request({
          url: `/maintain/leaderboard/personal/loop-machine/update-info`,
          method: `post`,
          data: this.loopData,
        });

        Message({
          message: "Apply data success",
          type: "success",
          duration: 5 * 1000,
        });

        this.$root.$emit("loop-machine-change-loading", false);
        this.$root.$emit("loop-machine-refresh-data");
      }
      catch (ex) {
        Message({
          message: `Apply data Error ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });

        this.$root.$emit("loop-machine-change-loading", false);
      }
    },

    async handleChangeState(isActive){
      try {
        this.$root.$emit("loop-machine-change-loading", true);

        let response = await request({
          url: `/maintain/leaderboard/personal/loop-machine/change-active`,
          method: `post`,
          data: {
            TypeLb: this.loopData.TypeLb,
            Active: isActive,
          },
        });

        Message({
          message: "Change state success",
          type: "success",
          duration: 5 * 1000,
        });

        this.$root.$emit("loop-machine-change-loading", false);
        this.$root.$emit("loop-machine-refresh-data");
      }
      catch (ex) {
        Message({
          message: `Change state Error ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });

        this.$root.$emit("loop-machine-change-loading", false);
      }
    },

    awaitDelay(ms){
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      })
    },

    async autoCountDown() {
      let breakFunction = false;
      while (!breakFunction) {
        if (this.countDownNextSetUp > 0) {
          this.countDownNextSetUp -= 1;
        }

        await this.awaitDelay(1000);
      }
    },

    updateCountDownTime(){
      this.countDownNextSetUp = (this.currentData.TimeStart - this.currentData.SetupBefore) - moment().unix();
    },
    parseDataToView() {
      this.dateInputTimeStartServer = this.currentData.TimeStart * 1000;
      this.updateCountDownTime();
    },

    handleOnEventDurationChange(value){
      this.loopData.EventDuration = value;
    },
    handleOnTimeDelayChange(value){
      this.loopData.TimeDelay = value;
    },
    handleOnComingSoonBeforeChange(value){
      this.loopData.ComingSoonBefore = value;
    },
    handleOnSetupBeforeChange(value){
      this.loopData.SetupBefore = value;
      this.updateCountDownTime();
    },

    generateMLBFileContentOfClanReward(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let data = listData[j];
        let tempString = `${data.id}\t${data.title}\t${data.rankMin}\t${data.rankMax}\t${data.requiredScore}\t${data.rewards}`;
        dataList.push(tempString);
      }

      return GenerateMLBFileContent(`LeaderBoardRewardsConfig`,
          ['index', 'Title', 'RankMin', 'RankMax', 'RequiredScore', 'Rewards'],
          ['int', 'string', 'int', 'int', 'int', 'string'],
          dataList);
    },

    generateMLBFileContentOfClanSegment(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let data = listData[j];
        let tempString = `${data.name}\t${data.total}\t${data.size}`;
        dataList.push(tempString);
      }

      return GenerateMLBFileContent(`LeaderBoardClanSegmentConfig`,
          ['GroupName', 'TotalLDB', 'TotalClan'],
          ['string', 'int', 'int'],
          dataList);
    },
    async onClanRewardFileListChange(fileList) {
      this.isLoading = true;
      this.loopData.ClanRewardData = [];

      for (let i = 0; i < fileList.length; i++) {
        //not multi file
        if (i >= 1) {
          break
        }

        if (fileList[i].raw) {
          fileList[i].rawText = await ReadFile(fileList[i].raw);
        }

        let clanInfoTemp = ParseMLBfile(fileList[i].rawText);
        let clanInfo = [];
        for (let j = 0; j < clanInfoTemp.length; j++) {
          let data = clanInfoTemp[j];
          clanInfo.push({
            id: data.index,
            title: data.Title,
            rankMin: data.RankMin,
            rankMax: data.RankMax,
            requiredScore: data.RequiredScore,
            rewards: data.Rewards,
          });
        }

        this.loopData.ClanRewardData = clanInfo;
      }

      this.isLoading = false;
    },
    async handleChangeClanRewardFileList(file, fileList) {
      await this.onClanRewardFileListChange(fileList);
    },
    async handleRemoveClanRewardFileList(file, fileList) {
      await this.onClanRewardFileListChange(fileList);
    },
    handleDownloadCurrentClanRewardFile() {
      if (this.loopData.ClanRewardData.length <= 0) {
        return;
      }

      DownloadFile('LeaderBoardGiftsConfigClan_Total.mlb',
          this.generateMLBFileContentOfClanReward(this.loopData.ClanRewardData));
    },

    async onClanSegmentFileListChange(fileList) {
      this.isLoading = true;
      this.loopData.ClanSegmentData = [];

      for (let i = 0; i < fileList.length; i++) {
        //not multi file
        if (i >= 1) {
          break
        }

        if (fileList[i].raw) {
          fileList[i].rawText = await ReadFile(fileList[i].raw);
        }

        let clanSegmentTemp = ParseMLBfile(fileList[i].rawText);
        let clanSegment = [];
        for (let j = 0; j < clanSegmentTemp.length; j++) {
          let data = clanSegmentTemp[j];
          clanSegment.push({
            name: data.GroupName,
            total: data.TotalLDB,
            size: data.TotalClan,
          });

        }

        this.loopData.ClanSegmentData = clanSegment;
      }

      this.isLoading = false;
    },
    async handleChangeClanSegmentFileList(file, fileList) {
      await this.onClanSegmentFileListChange(fileList);
    },
    async handleRemoveClanSegmentFileList(file, fileList) {
      await this.onClanSegmentFileListChange(fileList);
    },
    handleDownloadCurrentClanSegmentFile() {
      if (this.loopData.ClanSegmentData.length <= 0) {
        return;
      }

      DownloadFile('LeaderBoardClanSegmentConfig.mlb',
          this.generateMLBFileContentOfClanSegment(this.loopData.ClanSegmentData));
    },
  },
  computed: {
    currentData: {
      get: function() {
        return this.loopData;
      },
      set: function (value) {
        this.loopData = value;
      }
    },
    nextSetUpCountDown: {
      get: function() {
        let remainSecond = this.countDownNextSetUp;
        if (remainSecond <= 0) {
          return "SETTING => Wait";
        }

        let totalDay = Math.floor(remainSecond / 86400);

        remainSecond = remainSecond - totalDay * 86400;
        let totalHour = Math.floor(remainSecond / 3600);

        remainSecond = remainSecond - totalHour * 3600;
        let totalMinute = Math.floor(remainSecond / 60);

        remainSecond = remainSecond - totalMinute * 60;

        let ret = "";
        if (totalDay > 0) {
          ret += `${totalDay} day`;
        }

        ret += ` ${String(totalHour).padStart(2, '0')}`
        ret += `:${String(totalMinute).padStart(2, '0')}`
        ret += `:${String(remainSecond).padStart(2, '0')}`

        return ret;
      },
    },
    currentErrorMsg: {
      get: function () {

        let ret = [];
        // let secondNow = moment().unix();
        // let setupBefore = this.loopData.TimeStart - this.loopData.SetupBefore;
        // if (secondNow > setupBefore) {
        //   ret.push(`Thời gian bắt đầu không kịp`);
        // }

        if (this.loopData.CurrentIndexConfig >= this.loopData.MaxIndexConfig) {
          ret.push(`Index loop > index max`);
        }

        return ret;
      }
    },

    dateInputTimeStart: {
      get: function() {
        return this.currentData.TimeStart * 1000;
      },
      set: function(value) {
        this.currentData.TimeStart = value / 1000;
        this.updateCountDownTime();
      }
    },

    clanRewardFileList: {
      get: function() {
        let ret = [];

        if (this.currentData.ClanRewardData.length > 0) {
          ret.push(
              {
                name: `LeaderBoardGiftsConfigClan_Total.mlb`,
                rawText: this.generateMLBFileContentOfClanReward(this.currentData.ClanRewardData),
              });
        }

        return ret;
      }
    },

    clanSegmentFileList: {
      get: function() {
        let ret = [];

        if (this.currentData.ClanSegmentData.length > 0) {
          ret.push(
              {
                name: `LeaderBoardClanSegmentConfig.mlb`,
                rawText: this.generateMLBFileContentOfClanSegment(this.currentData.ClanSegmentData),
              });
        }

        return ret;
      }
    },
  },
  watch: {
    data(newValue, oldValue) {
      this.loopData = newValue;
      this.parseDataToView();
    }
  },
  created() {
    this.autoCountDown();
  },
};

</script>

<style scoped>
.red-text {
  color: red;
}
</style>