<template>
  <el-tabs tab-position="left" v-model="tabActive">
    <el-tab-pane label="Lbq" name="Lbq">
      <LeaderBoardData :data="allData.Lbq" :on-change="onChangeDataLb"/>
    </el-tab-pane>
    <el-tab-pane label="Endless" name="Endless">
      <LeaderBoardData :data="allData.Endless" :on-change="onChangeDataLb"/>
    </el-tab-pane>
    <el-tab-pane label="RaidbossGrand" name="RaidbossGrand">
      <LeaderBoardGrandBossData :data="allData.RbGrand" :on-change="onChangeDataLb"/>
    </el-tab-pane>
    <el-tab-pane label="RaidbossLittle" name="RaidbossLittle">
      <LeaderBoardData :data="allData.RbLittle" :on-change="onChangeDataLb"/>
    </el-tab-pane>
    <el-tab-pane label="Versus" name="Versus">
      <LeaderBoardData :data="allData.Versus" :on-change="onChangeDataLb"/>
    </el-tab-pane>
  </el-tabs>
</template>

<script>

import LeaderBoardData from "./leaderboardData.vue"
import LeaderBoardGrandBossData from "./leaderboardGrandBossData.vue"

function noop() {}

export default {
  name: "event",
  components: {
    LeaderBoardData,
    LeaderBoardGrandBossData
  },
  props: {
    allData : {},
    onChange: {
      type: Function,
      default: noop
    },
  },
  data: () => {
    return {
      tabActive: "Lbq",
    }
  },
  methods: {
    onChangeDataLb(typeLb) {
      console.log(`tabEdit onChangeDataLb ${typeLb} -> ${this.allData[typeLb].IsActive}`);
    },
  }
};

</script>