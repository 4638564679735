<template>
  <full-calendar :events="events" :config="config" />
</template>

<script>
import { FullCalendar } from 'vue-full-calendar';
import 'fullcalendar/dist/fullcalendar.css'

export default {
  name: 'Calendar',
  props: ['events'],
  components: {
    FullCalendar
  },
  data() {
    return {
      config: {
        defaultView: 'month',
        editable: false,
        selectable: false,
        selectHelper: false,
      }
    }
  }
}
</script>

<style>

</style>
