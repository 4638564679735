<template>
  <div v-loading="this.isLoading">
    <el-form ref="form" label-width="300px">
      <MyFormItem label="Status Leaderboard" tooltip="Current Status">
        <el-input class="form-input" v-model="rewardMachineStatus" size="normal" disabled/>
        <br>
        <br>
        <el-button type="primary" @click="getCurrentStatus()">
          RefreshStatus
        </el-button>
      </MyFormItem>

      <UploadTitle>Send data</UploadTitle>
      <MyFormItem label="Send data" tooltip="Đẩy file Reward Error lên">
        <el-upload
            drag
            action=""
            :file-list="rewardErrorFileList"
            :auto-upload="false"
            ref=""
            :multiple="false"
            :on-change="handleChangeRewardFileList"
            :on-remove="handleChangeRewardFileList"
            class="file-list-item">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
        </el-upload>
      </MyFormItem>

      <el-button type="primary" @click="doSendData()">
        Set Data
      </el-button>
      <br>
      <br>
      <el-row :gutter="20" type="flex" justify="start">
        <el-col :offset="0">
          <el-button type="primary" @click="downloadLastData()">
            Download Last Data file
          </el-button>
        </el-col>
        <el-col :offset="0">
          <el-button type="danger" @click="forceStop()">
            Force Stop
          </el-button>
        </el-col>
      </el-row>

    </el-form>
  </div>
</template>

<script>
import MyFormItem from "@/views/loop-machine/component/myFormItem.vue";
import request from "@/utils/request-service-base";
import {Message} from "element-ui";
import moment from "moment/moment";
import UploadTitle from "@/views/loop-machine/component/uploadTitle.vue";
import ReadFile from '@/helpers/readFile'
import store from "@/store";
import { getToken, getOSToken } from '@/utils/auth'
import settings from '@/settings.js'

export default {
  components: {
    UploadTitle,
    MyFormItem,
  },
  data: () => {
    return {
      isLoading: true,
      rewardErrorData: {
        Guid: "",
        TypeLb: null,
        EventName: "",
        EventId: 0,
        Start: 0,
        End: 0,
        RewardFail: [],
      },
      rewardMachineStatus: "Loading",
      downloadUrl: "",
    }
  },
  computed: {
    rewardErrorFileList: {
      get: function() {
        let ret = [];

        if (this.rewardErrorData.Guid !== "")
        {
          ret.push({
            name: `${this.rewardErrorData.Guid}-${this.rewardErrorData.TypeLb}-${this.rewardErrorData.EventId}`,
            rawText: JSON.stringify(this.rewardErrorData),
          });
        }

        return ret;
      }
    },
  },
  async beforeMount() {
    await this.getCurrentStatus();

    let OS = getOSToken();

    if (OS && OS.toLowerCase() === 'ios') {
      this.downloadUrl = settings.urlServiceBase_ios;
    }
    else {
      this.downloadUrl = settings.urlServiceBase;
    }

    this.isLoading = false;
  },
  methods: {
    async handleChangeRewardFileList(file, fileList) {
      this.rewardErrorData = {};

      this.isLoading = true;

      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].raw) {
          fileList[i].rawText = await ReadFile(fileList[i].raw);
        }

        this.rewardErrorData = JSON.parse(fileList[i].rawText);
      }

      this.isLoading = false;
    },

    async doSendData() {
      try {
        if (this.rewardErrorData.Guid === "") {
          return;
        }

        this.isLoading = true;
        await request({
          url: `/maintain/leaderboard/personal/reward-machine/set-data`,
          method: `post`,
          data: this.rewardErrorData
        });

        this.rewardErrorData = {};
        await this.getCurrentStatus();

        this.isLoading = false;
      }
      catch (ex) {
        Message({
          message: `Send Data error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.isLoading = false;
      }
    },

    async downloadLastData() {
      try {
        console.log(`downloadUrl = ${this.downloadUrl}`);
        window.open(`${this.downloadUrl}/maintain/leaderboard/personal/reward-machine/download-last-data`, "_blank");
      }
      catch (ex) {
        Message({
          message: `Download last data error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }
    },

    async forceStop() {
      try {
        this.isLoading = true;
        await request({
          url: `/maintain/leaderboard/personal/reward-machine/force-stop`,
          method: `get`,
        });

        await this.getCurrentStatus();

        this.isLoading = false;
      }
      catch (ex) {
        Message({
          message: `Force stop error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.isLoading = false;
      }
    },

    async getCurrentStatus() {
      try {
        let response = await request({
          url: `/maintain/leaderboard/personal/reward-machine/get-current-status`,
          method: `get`,
        });

        this.rewardMachineStatus = response.data.Status;

      }
      catch (ex) {
        Message({
          message: `Get current status error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }
    },
  }
}
</script>