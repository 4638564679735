<template>
  <div>
    <el-header height="300">
      <el-row :gutter="20" type="flex" align="middle" justify="center">
        <el-col :span="6" :offset="0">
          <el-button type="danger" v-if="currentData.IsActive === true" @click="handleChangeState(false)">
            Activating - Click to hold
          </el-button>
          <el-button type="primary" v-if="currentData.IsActive === false" @click="handleChangeState(true)">
            Holding - Click to Active
          </el-button>
        </el-col>
        <el-col :offset="0">
          <h1>THE NEXT EVENT WILL BE SET UP AUTOMATICALLY <span class="red-text">{{ this.nextSetUpCountDown }}</span></h1>
        </el-col>
        <el-col :span="10" :offset="0">
          <el-row>
            <el-button
                v-if="currentErrorMsg.length === 0"
                type="success"
                size="default"
                @click="handleApplyData"
            >Apply Data
            </el-button>
            <el-button
                v-if="currentErrorMsg.length > 0"
                type="danger"
                size="default"
                @click="doNothingFunction"
            >Apply Data
            </el-button>
          </el-row>
          <el-row>
            <ul>
              <li  v-for="msg in this.currentErrorMsg" :key="msg">
                {{ msg }}
              </li>
            </ul>
          </el-row>

        </el-col>
      </el-row>
    </el-header>

    <el-form ref="form" label-width="300px">
      <MyFormItem label="Time Start" tooltip="Thời gian bắt đầu của Event gần nhất tiếp theo (giây)">
        <el-date-picker
            v-model="dateInputTimeStart"
            type="datetime"
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>

      <MyFormItem label="Time Start Server" tooltip="Thời gian bắt đầu của Event gần nhất tiếp theo (giây)">
        <el-date-picker
            v-model="dateInputTimeStartServer"
            type="datetime"
            disabled
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>

      <MyFormItem label="Event Duration" tooltip="Thời gian kéo dài của event (giây)">
        <TimeInput :value="currentData.EventDuration" :on-change="handleOnEventDurationChange"/>
      </MyFormItem>

      <MyFormItem  label="Time Delay" tooltip="Thời gian chờ đến lúc lên Event tiếp theo (giây)">
        <TimeInput :value="currentData.TimeDelay" :on-change="handleOnTimeDelayChange"/>
      </MyFormItem>

      <MyFormItem label="Coming soon before" tooltip="Thời gian Coming Soon trước Time Start bao lâu (giây)">
        <TimeInput :value="currentData.ComingSoonBefore" :on-change="handleOnComingSoonBeforeChange"/>
      </MyFormItem>

      <MyFormItem label="Setup before" tooltip="Thời gian Setup trước Time Start bao lâu (giây)">
        <TimeInput :value="currentData.SetupBefore" :on-change="handleOnSetupBeforeChange"/>
      </MyFormItem>

      <MyFormItem label="Current Index Config" tooltip="Index config sử dụng cho đợt event sau">
        <el-input-number v-model="currentData.CurrentIndexConfig" size="normal" label="label" :step="1" :controls="true" :min="0" :max="currentData.MaxIndexConfig - 1"/>
      </MyFormItem>

      <MyFormItem label="Max Index Config" tooltip="Số lượng config có trong bundle">
        <el-input-number v-model="currentData.MaxIndexConfig" size="normal" label="label" :step="1" :controls="true" :min="1"/>
      </MyFormItem>

    </el-form>
  </div>
</template>

<script>

import moment from "moment/moment";
import MyFormItem from "@/views/loop-machine/component/myFormItem.vue";
import TimeInput from "@/views/loop-machine/component/timeInput.vue";
import GenerateMLBFileContent from "@/helpers/generateMLBFileContent";
import ReadFile from "@/helpers/readFile";
import ParseMLBfile from "@/helpers/parseMLBfile";
import DownloadFile from "@/helpers/downloadFile";
import request from "@/utils/request-service-base";
import {Message} from "element-ui";

function noop() {}

export default {
  name: "loopMachine",
  components: {
    TimeInput,
    MyFormItem,
  },
  data() {
    return {
      countDownNextSetUp: 0,

      dateInputTimeStartServer: 0,
    }
  },
  props: {
    loopData: {},
  },
  methods: {
    doNothingFunction(){
    },

    async handleApplyData(){
      try {
        this.$root.$emit("event-change-loading", true);

        let response = await request({
          url: `/maintain/event/loop-machine/update-info`,
          method: `post`,
          data: this.loopData,
        });

        Message({
          message: "Apply data success",
          type: "success",
          duration: 5 * 1000,
        });

        this.$root.$emit("event-change-loading", false);
        this.$root.$emit("event-refresh-data");
      }
      catch (ex) {
        Message({
          message: `Apply data Error ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });

        this.$root.$emit("event-change-loading", false);
      }
    },

    async handleChangeState(isActive){
      try {
        this.$root.$emit("event-change-loading", true);

        let response = await request({
          url: `/maintain/event/loop-machine/change-active`,
          method: `post`,
          data: {
            TypeEvent: this.loopData.TypeEvent,
            Active: isActive,
          },
        });

        Message({
          message: "Change state success",
          type: "success",
          duration: 5 * 1000,
        });

        this.$root.$emit("event-change-loading", false);
        this.$root.$emit("event-refresh-data");
      }
      catch (ex) {
        Message({
          message: `Change state Error ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });

        this.$root.$emit("event-change-loading", false);
      }
    },

    awaitDelay(ms){
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      })
    },

    async autoCountDown() {
      let breakFunction = false;
      while (!breakFunction) {
        if (this.countDownNextSetUp > 0) {
          this.countDownNextSetUp -= 1;
        }

        await this.awaitDelay(1000);
      }
    },

    updateCountDownTime(){
      this.countDownNextSetUp = (this.currentData.TimeStart - this.currentData.SetupBefore) - moment().unix();
    },
    parseDataToView() {
      this.dateInputTimeStartServer = this.currentData.TimeStart * 1000;
      this.updateCountDownTime();
    },

    handleOnEventDurationChange(value){
      this.loopData.EventDuration = value;
    },
    handleOnTimeDelayChange(value){
      this.loopData.TimeDelay = value;
    },
    handleOnComingSoonBeforeChange(value){
      this.loopData.ComingSoonBefore = value;
    },
    handleOnSetupBeforeChange(value){
      this.loopData.SetupBefore = value;
      this.updateCountDownTime();
    },
  },
  computed: {
    currentData: {
      get: function() {
        return this.loopData;
      },
      set: function (value) {
        this.loopData = value;
      }
    },
    nextSetUpCountDown: {
      get: function() {
        let remainSecond = this.countDownNextSetUp;
        if (remainSecond <= 0) {
          return "SETTING => Wait";
        }

        let totalDay = Math.floor(remainSecond / 86400);

        remainSecond = remainSecond - totalDay * 86400;
        let totalHour = Math.floor(remainSecond / 3600);

        remainSecond = remainSecond - totalHour * 3600;
        let totalMinute = Math.floor(remainSecond / 60);

        remainSecond = remainSecond - totalMinute * 60;

        let ret = "";
        if (totalDay > 0) {
          ret += `${totalDay} day`;
        }

        ret += ` ${String(totalHour).padStart(2, '0')}`
        ret += `:${String(totalMinute).padStart(2, '0')}`
        ret += `:${String(remainSecond).padStart(2, '0')}`

        return ret;
      },
    },
    currentErrorMsg: {
      get: function () {

        let ret = [];
        // let secondNow = moment().unix();
        // let setupBefore = this.loopData.TimeStart - this.loopData.SetupBefore;
        // if (secondNow > setupBefore) {
        //   ret.push(`Thời gian bắt đầu không kịp`);
        // }

        if (this.loopData.CurrentIndexConfig >= this.loopData.MaxIndexConfig) {
          ret.push(`Index loop > index max`);
        }

        return ret;
      }
    },

    dateInputTimeStart: {
      get: function() {
        return this.currentData.TimeStart * 1000;
      },
      set: function(value) {
        this.currentData.TimeStart = value / 1000;
        this.updateCountDownTime();
      }
    },
  },
  created() {
    this.autoCountDown();
    this.parseDataToView();
  },
};

</script>

<style scoped>
.red-text {
  color: red;
}
</style>