<template>
  <el-tabs tab-position="left" v-model="tabActive">
    <el-tab-pane label="LuckyWheel" name="LuckyWheel">
      <LoopMachineData :loop-data="allData.LuckyWheel"/>
    </el-tab-pane>
<!--    <el-tab-pane label="NonStopOffer" name="NonStopOffer">-->
<!--      <LoopMachineData :data="allData.NonStopOffer"/>-->
<!--    </el-tab-pane>-->
    <el-tab-pane label="Recharge" name="Recharge">
      <LoopMachineData :loop-data="allData.Recharge"/>
    </el-tab-pane>
<!--    <el-tab-pane label="Wheel" name="Wheel">-->
<!--      <LoopMachineData :data="allData.Wheel"/>-->
<!--    </el-tab-pane>-->
  </el-tabs>
</template>

<script>

import LoopMachineData from "./eventLoopMachineData.vue"

function noop() {}

export default {
  name: "loopMachine",
  components: {
    LoopMachineData
  },
  props: {
    allData : {},
  },
  data: () => {
    return {
      tabActive: "LuckyWheel",
    }
  },
  methods: {
    onChangeDataLb(typeEvent) {
    },
  },
  computed: {
  },
};

</script>