<template>
  <el-tabs tab-position="left" v-model="tabActive">
    <el-tab-pane label="LuckyWheel" name="LuckyWheel">
      <EventData :data="allData.LuckyWheel" :on-change="onChangeDataLb"/>
    </el-tab-pane>
<!--    <el-tab-pane label="NonStopOffer" name="NonStopOffer">-->
<!--      <EventData :data="allData.NonStopOffer" :on-change="onChangeDataLb"/>-->
<!--    </el-tab-pane>-->
    <el-tab-pane label="Recharge" name="Recharge">
      <EventData :data="allData.Recharge" :on-change="onChangeDataLb"/>
    </el-tab-pane>
<!--    <el-tab-pane label="Wheel" name="Wheel">-->
<!--      <EventData :data="allData.Wheel" :on-change="onChangeDataLb"/>-->
<!--    </el-tab-pane>-->
  </el-tabs>
</template>

<script>

import EventData from "./eventData.vue"

function noop() {}

export default {
  name: "event",
  components: {
    EventData,
  },
  props: {
    allData : {},
    onChange: {
      type: Function,
      default: noop
    },
  },
  data: () => {
    return {
      tabActive: "LuckyWheel",
    }
  },
  methods: {
    onChangeDataLb(typeEvent) {
      //console.log(`tabEdit onChangeDataEvent ${typeEvent} -> ${this.allData[typeEvent].IsActive}`);
    },
  }
};

</script>