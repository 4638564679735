<template>
  <div v-loading="this.isLoading">
    <el-tabs type="border-card" tab-position="top" v-model="tabActive">
      <!-- Schedule -->
      <el-tab-pane label="Schedule" name="Schedule">
        <FullCalendar :events="lbScheduleData"/>
      </el-tab-pane>

      <!-- Detail -->
      <el-tab-pane label="Detail" name="Detail">
        <TabEdit :all-data="allLbData" v-if="Object.keys(this.allLbData).length > 0"/>
      </el-tab-pane>

      <!-- Rank -->
      <el-tab-pane label="Rank" name="Rank">
        <RankTab :all-data="allLbData" v-if="Object.keys(this.allLbData).length > 0"/>
      </el-tab-pane>

      <!-- Maintain -->
      <el-tab-pane label="Maintain" name="Maintain">
        <MaintainTab :all-data="allLbData" v-if="Object.keys(this.allLbData).length > 0"/>
      </el-tab-pane>

      <!-- LoopMachine -->
      <el-tab-pane label="LoopMachine" name="LoopMachine">
        <LoopMachine/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>

import moment from 'moment'
import FullCalendar from "../../../components/FullCalendar"
import request from "@/utils/request-service-base";
import TabEdit from "./lbDetailTab.vue"
import RankTab from "./leaderboardRank.vue"
import MaintainTab from "./maintain.vue"
import {Message} from "element-ui";
import LoopMachine from "./loopMachine.vue";

export default {
  components: {
    FullCalendar,
    TabEdit,
    RankTab,
    MaintainTab,
    LoopMachine,
  },
  data: () => {
    return {
      isLoading: true,
      tabActive: "Schedule",

      lbScheduleData: [],
      allLbData: {},
    }
  },

  async beforeMount() {
    await this.getDataFromServer();

    this.isLoading = false;
  },
  methods: {
    async getDataFromServer() {
      try {
        let data = await request({
          url: `/maintain/leaderboard/personal/get-leaderboard`,
          method: `get`
        });

        this.allLbData = data.data;
        this.lbScheduleData = [];

        for (let eventType in this.allLbData) {
          let eventData = this.allLbData[eventType];

          // if (eventData.StatusLb !== "Active") {
          //   continue;
          // }

          let stringDateStart = `${moment.unix(eventData.Time.Start).local().format("DD-MM-YYYY HH:mm")}`;
          let stringDateEnd = `${moment.unix(eventData.Time.End).local().format("DD-MM-YYYY HH:mm")}`;

          let pushData = {
            title: `${eventType} : ${stringDateStart} -> ${stringDateEnd}`,
            start: moment.unix(eventData.Time.Start),
            end: moment.unix(eventData.Time.End),
            textColor: "#000000",
          };
          switch (eventType) {
            case "Lbq":
              pushData.color = "#C2FE67";
              break;
            case "Endless":
              pushData.color = "#FE67AD";
              break;
            case "RbGrand":
              pushData.color = "#67D6FE";
              break;
            case "RbLittle":
              pushData.color = "#FEF667";
              break;
          }

          this.lbScheduleData.push(pushData);
        }
      } catch (ex) {
        Message({
          message: `Get data from server error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }
    },
  },

  mounted() {
    this.$root.$on(`event-change-loading`, (isEnable) => {
      this.isLoading = isEnable;
    });

    this.$root.$on(`event-refresh-data`, async () => {
      this.isLoading = true;
      await this.getDataFromServer();

      this.isLoading = false;
    })
  }
}
</script>

<style>

</style>