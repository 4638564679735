<template>
  <div class="app-container">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="LeaderBoard" name="LeaderBoard">
        <Leaderboard/>
      </el-tab-pane>
      <el-tab-pane label="Other" name="Other">
        <Other/>
      </el-tab-pane>
      <!-- User -->
      <el-tab-pane label="User" name="User">
        <User/>
      </el-tab-pane>
      <!-- RewardMachine -->
      <el-tab-pane label="RewardMachine" name="RewardMachine">
        <RewardMachine/>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
import Leaderboard from './leaderboard';
import Other from './other';
import User from "./user/index.vue";
import RewardMachine from "./rewardMachine/index.vue";

export default {
  name: "event",
  components: {
    Leaderboard,
    Other,
    User,
    RewardMachine,
  },
  data() {
    return {
      activeTab: "LeaderBoard"
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
